import React, {useState, FormEvent} from 'react'
import {Link, navigate} from 'gatsby'
import PropTypes from 'prop-types'
import Layout from '../components/layout'
import Head from '../components/head'
import {useAuth} from '../util/use-auth'

interface Props {
  location: null | {
    state: {
      flash: string
    }
  }
}

const Login: React.FC<Props> = ({location}) => {
  const [message, setMessage] = useState(null as string | null)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const auth = useAuth()

  const flash = location?.state?.flash

  const validateForm = (): boolean => {
    return email.length > 0 && password.length > 0
  }

  const handleSubmit = async (event: FormEvent): Promise<void> => {
    event.preventDefault()
    if (!auth) return
    setMessage(null)
    setIsLoading(true)
    try {
      // TODO: handle multi factor auth, password reset
      await auth.signIn(email, password)
      navigate(auth.getReturnTo() || '/')
    } catch (error) {
      const code = (error as any).code
      const message = (error as any).message

      if (code === 'UserNotConfirmedException') {
        window.location.href = `/confirm?email=${encodeURIComponent(email)}`
      } else if (code === 'PasswordResetRequiredException') {
        window.location.href = `/reset?email=${encodeURIComponent(email)}`
      } else if (code === 'NotAuthorizedException') {
        setMessage(message)
      } else if (code === 'UserNotFoundException') {
        setMessage(message)
      } else {
        setMessage(message)
      }
      console.error({error})
    }
    setIsLoading(false)
  }

  return (
    <Layout>
      <Head title="Login" />
      <div>
        {flash && <div className="flash">{flash}</div>}
        <form onSubmit={handleSubmit}>
          <label>
            Email
            <br />
            <input
              name="email"
              type="email"
              value={email}
              onChange={(e): void => {
                setMessage(null)
                setEmail(e.target.value)
              }}
            />
          </label>
          <label>
            Password
            <br />
            <input
              name="password"
              type="password"
              value={password}
              onChange={(e): void => {
                setMessage(null)
                setPassword(e.target.value)
              }}
            />
          </label>
          <div className="error">{message}</div>
          <div className="pt-4">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white text-sm font-bold py-2 px-4 rounded-full"
              disabled={!validateForm() || isLoading}>
              {isLoading ? 'Logging in...' : 'Login'}
            </button>
          </div>
        </form>
      </div>

      <br />

      <div>
        Need an account? <Link to="/signup/">Sign up</Link>.
      </div>

      <Link to="/">Go back to the homepage</Link>
    </Layout>
  )
}

Login.propTypes = {
  location: PropTypes.any,
}

export default Login
